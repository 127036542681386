.stacked-bars-widget {
  padding: 1rem;
  padding-bottom: 0;

  .title {
    margin-bottom: 1rem;
  }
  .bar-title {
    font-size: 1.25rem;
    font-weight: bold;
  }
  .bar-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;

    .stacked-bar {
      width: 100%;
      white-space: nowrap;
      .bar {
        display: inline-block;
        height: 110px;
      }
    }

    .bar-side-container {
      display: flex;
      flex-direction: row;
      align-items: stretch;
    }

    .bar-side {
      display: flex;
      flex-direction: row;
      align-items: stretch;
    }
  }
}
