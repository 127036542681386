.widget {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  font-weight: 300;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  .title {
    text-transform: uppercase;
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
  }
  .controls {
    position: absolute;
    top: 4px;
    right: 4px;
    font-size: 0.75rem;
    display: flex;
    flex-direction: row;
    > * {
      cursor: pointer;
      margin-left: 0.5rem;
    }
  }
  .controls.only-on-hover {
    display: none;
  }
  &:hover {
    .controls.only-on-hover {
      display: block;
    }
  }
}
