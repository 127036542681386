.list-widget {
  position: relative;

  .stacked-bar .bar {
    display: inline-block;
  }
  .list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .list-table {
    width: 100%;
    max-width: 100%;

    tr {
      cursor: pointer;
    }
  }

  .list-bar-cell {
    width: 100%;
  }

  .list-value-cell,
  .list-label-cell {
    padding: 0 0.5rem;
  }

  .max-width-hack {
    max-width: 14rem;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .list-label-cell {
    text-align: right;
  }
}
