.filter-viewer {
  .filter {
    display: inline-flex;
    flex-direction: row;
    text-transform: uppercase;
    font-weight: 300;
    align-items: center;
    padding: 0.25rem 0.75rem;
    border: 1px solid #ccc;
    border-radius: 1rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .filter-variable {
    margin-left: 0.5rem;
    &:first-child {
      margin-left: 0;
    }
  }
  .filter-values {
    margin-left: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    .filter-value {
      font-weight: bold;
    }
    .filter-conjuction {
      margin: 0 0.375rem;
      font-size: 0.75rem;
    }
  }

  .filter-controls {
    .btn:last-child {
      padding-right: 0;
    }
  }
}
