@import url('https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap');

$font-family-serif: Roboto, serif;

$blue: #1269a2;
$secondary: #7bc6db;
$dark: #02263d;
$gray-dark: #8c8e90;
$gray-light: #e4e5e6;

@import 'node_modules/bootstrap/scss/bootstrap';
