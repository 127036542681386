.pie-widget {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .graphic-container {
    overflow: hidden;
    flex: 1 0;
  }

  svg {
    max-height: 100%;
  }

  .legend {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 0.85rem;
    margin-top: 1rem;
    justify-content: center;
  }

  .legend > * {
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }

  .legend .legend-color {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin-bottom: 2px;
    overflow: hidden;
    white-space: nowrap;
  }

  .legend-color-swatch {
    display: inline-block;
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 3px;
  }
}
