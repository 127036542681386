.color-picker-button {
  .color {
    width: 2.25rem;
    height: 100%;
    border-radius: 2px;
  }

  .swatch {
    display: block;
    cursor: pointer;
    height: 100%;
    width: 100%;
  }

  .popover {
    position: absolute;
    z-index: 2;
  }

  .cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}
