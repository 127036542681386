.bars {
  .bar {
    border-top: 1px solid #ced4da;
    margin: 1rem 0;
    padding: 1rem 0;
  }
  .bar-variables {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    .controls {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-items: stretch;
      .btn {
        height: 100%;
      }
    }
  }
}

.modal {
  text-align: center;
}

.modal-dialog {
  text-align: left;
  display: inline-block !important;
  min-width: 500px !important;
  max-width: 100vw !important;
}
