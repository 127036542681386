.value-fields {
  .field {
    // display: flex;
    // flex-direction: row;
  }
  .swatch {
    width: 1rem;
    height: 1rem;
  }
  table {
    width: 100%;
  }
  .variable-name {
    line-height: 40px;
    margin: 0 4px;
  }
  .drag-handle {
    > span {
      display: inline-block;
      height: 20px;
      margin: 8px 4px 0;
      width: 8px;
      background-image: radial-gradient(#333 40%, transparent 40%);
      background-size: 4px 4px;
      background-position: 0 100%;
    }
  }
}
