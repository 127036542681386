@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap');

body {
  font-family: Inter, sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: 1rem;
  .nav-bar {
    display: flex;
    min-width: 1200px;
    .logo-container {
      img {
        height: 3rem;
      }
    }
    .user-controls {
      display: flex;
      justify-content: flex-end;
      align-items: baseline;
    }
  }
  main {
    margin-top: 2rem;
    flex: 1;
  }

  .btn {
    svg {
      display: inline;
      vertical-align: initial;
    }
  }
}
