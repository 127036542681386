.widget-adder {
  .widget-adder-header {
    border: 1px solid black;
    padding: 0.5rem 0.75rem;
    margin: 0.5rem 0;
    background-color: #333;
    color: #fafafa;
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .widget-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.125rem 0.75rem;
    .widget-name {
      text-transform: uppercase;
      font-size: 0.875rem;
    }
    .widget-widgets {
      white-space: nowrap;
    }
    .widget-placeholder {
      display: inline-block;
      width: 2rem;
    }
  }
}
