@import '~react-input-range/lib/css/index.css';

.slider-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .slider-container {
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
  }

  .input-range {
    margin-top: 1rem;
    margin-bottom: 1rem;

    .input-range__label {
      color: initial;
    }
  }
}
