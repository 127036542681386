.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 1rem;
  flex: 1;

  .spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 300;
    letter-spacing: 2px;
    text-align: center;

    .spinner {
      margin-bottom: 2.5rem;
    }
  }
}
